import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { map, shareReplay, take } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { Product } from "../classes/product";
import { ApiService } from "./api.service";
import { CachedItem, NgForage, NgForageCache } from "ngforage";

import "rxjs/add/observable/fromPromise";
import { User } from "../classes/user";
import { Store } from "@ngrx/store";
import { AppState, selectAuthState } from "../../_store/app.states";
import * as _ from "lodash";
import { isEmpty, isNull, orderBy, reverse, sortBy, uniq } from "lodash";
import { HelperService } from "./helper.service";

const CACHE_SIZE = 1;
export const DELIVER_PRICE = 500;
const state = {
  products: JSON.parse(localStorage.products || "[]"),
  wishlist: JSON.parse(localStorage.wishlistItems || "[]"),
  compare: JSON.parse(localStorage.compareItems || "[]"),
  cart: JSON.parse(localStorage.cartItems || "[]"),
  currentUser: JSON.parse(localStorage.currentUser || "{}"),
};

@Injectable({
  providedIn: "root",
})
export class ProductService {
  public Currency = { name: "Dollar", currency: "USD", price: 1 }; // Default Currency
  public OpenCart = false;

  // Get Products
  public Products: Observable<Product[]>;
  private categorySource = new Subject<any>();
  private productsCache$: Observable<Product[]>;

  // Get Products by category
  private productsCache: BehaviorSubject<Product[]> = new BehaviorSubject<
    Product[]
  >([]);
  ProductCached = this.productsCache.asObservable();
  private currentUser: User;
  openSideRatio = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private toastService: ToastrService,
    private readonly ngf: NgForage,
    private readonly cache: NgForageCache,
    private store: Store<AppState>,
    private helperService: HelperService
  ) {
    this.getUser();
  }

  public searchProducts(q) {
    return (this.Products = this.apiService.post(`searchProducts`, {
      method: "searchProducts",
      route: "product",
      data: { user: this.currentUser ?? null, searchTerm: q },
    })).map((response) => {
      if (!isNull(response) && response.data) {
        return response.data.map((product) => {
          return ProductService.transformProduct(product);
        });
      }
      return [];
    });
  }

  public get getProducts() {
    this.productsCache$ = Observable.fromPromise(
      this.getCachedItem("products")
    );
    if (!this.productsCache$) {
      this.productsCache$ = this.products.pipe(shareReplay(CACHE_SIZE));
    }
    return this.productsCache$;
    // if (!this.productsCache$) {
    //     this.productsCache$ = this.products.pipe(shareReplay(CACHE_SIZE));
    // }
    // return this.productsCache$;
  }

  get brand() {
    const uniqueBrands = [];
    return this.apiService
      .post(`brands`, {
        method: "getBrands",
        route: "product",
      })
      .pipe(
        take(1),
        // eslint-disable-next-line @typescript-eslint/no-shadow
        map((response) => {
          if (response && response.result === "OK") {
            response.data.map((brand) => {
              uniqueBrands.push(brand.name);
            });
            return uniqueBrands;
          }
          return [];
        })
      );
  }

  // Get Wishlist Items
  public get wishlistItems(): Observable<Product[]> {
    const itemsStream = new Observable((observer) => {
      observer.next(state.wishlist);
      observer.complete();
    });
    return itemsStream as Observable<Product[]>;
  }

  // Get Compare Items
  public get compareItems(): Observable<Product[]> {
    const itemsStream = new Observable((observer) => {
      observer.next(state.compare);
      observer.complete();
    });
    return itemsStream as Observable<Product[]>;
  }

  get Category() {
    return this.categorySource;
  }

  // Product
  private get products(): Observable<Product[]> {
    this.Products = this.apiService
      .post(`products`, {
        method: "getProducts",
        route: "product",
        data: { user: this.currentUser ?? null },
      })
      .pipe(
        take(1),
        // eslint-disable-next-line @typescript-eslint/no-shadow
        map((response) => {
          if (response && response.result === "OK") {
            response.data.map((product) => {
              return (product = ProductService.transformProduct(product));
            });
            // for order components
            this.setCacheItem("products", response.data);
            return response.data;
          }
          this.productsCache.next(response.data);
          return [];
        })
      );
    return this.Products;
  }

  public static transformProduct(product: Product) {
    if (!product.images) {
      product.images = [
        {
          src: "assets/images/product/placeholder.jpg",
        },
      ];
    } else if (isEmpty(JSON.stringify(product.images))) {
      product.images = [
        {
          src: "assets/images/product/placeholder.jpg",
        },
      ];
    } else {
      const img = product.images as unknown as string;
      product.images = JSON.parse(img) as Array<any>;
      /* product.images = images.map((image: any) => {
         image.src = image.src.includes("?alt")
           ? image.src.split("?alt")[0]
           : image.src;
         return image;
       });*/
    }
    if (!product.BILGISI) {
      product.BILGISI = "";
    }
    if (!product.TEKNIK) {
      product.TEKNIK = "";
    }

    // eslint-disable-next-line radix
    product.stock = parseInt(product.MBAKIYE) + parseInt(product.LBAKIYE);
    product.title = product.WEBACIKLAMA;

    product.conversion = 0;
    //
    if (product.currency === "USD" && product.discountPrice) {
      product.conversion =
        +(
          +JSON.parse(localStorage.getItem("conversion"))?.data?.rate *
          product.discountPrice
        ).toFixed() ?? 0;
    } else if (product.currency === "USD") {
      product.conversion =
        +(
          +JSON.parse(localStorage.getItem("conversion"))?.data?.rate *
          product.displayPrice
        ).toFixed() ?? 0;
    }

    return product;
  }

  private static sortByName(products, payload) {
    if (payload === "a-z") {
      return products.sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        } else if (a.title > b.title) {
          return 1;
        }
        return 0;
      });
    } else if (payload === "z-a") {
      return products.sort((a, b) => {
        if (a.title > b.title) {
          return -1;
        } else if (a.title < b.title) {
          return 1;
        }
      });
    }
  }

  private static sortByPrice(products, payload) {
    if (payload === "low") {
      return orderBy(
        products,
        [
          (product) =>
            product.discountPrice
              ? product.discountPrice
              : product.displayPrice,
        ],
        "asc"
      );
    } else if (payload === "high") {
      // return orderBy(products, "displayPrice", "desc");
      return orderBy(
        products,
        [
          (product) =>
            product.discountPrice
              ? product.discountPrice
              : product.displayPrice,
        ],
        "desc"
      );
    }
  }

  private static sortByStok(products, payload) {
    if (payload === "high-low-stok") {
      // for price high to low
      return products.sort((a, b) => {
        if (a.stock > b.stock) {
          return -1;
        } else if (a.stock < b.stock) {
          return 1;
        }
      });
    } else if (payload === "low-high-stok") {
      // for price high to low
      return products.sort((a, b) => {
        if (a.stock < b.stock) {
          return -1;
        } else if (a.stock > b.stock) {
          return 1;
        }
      });
    }
  }

  localUpperCase(value: string): string {
    return value.toLocaleUpperCase("tr-TR");
  }

  getUser() {
    this.store
      .select(selectAuthState)
      .pipe(map((userState: any) => userState.user))
      .subscribe((user: User) => {
        if (user) {
          this.currentUser = user;
        }
      });
  }

  getProductSections() {
    return this.apiService
      .post(`getFeaturedNewProducts`, {
        route: "product",
        method: "getFeaturedNewProducts",
        data: {
          section: "one",
          user: this.currentUser ?? null,
        },
      })
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-shadow
        map((response) => {
          if (response && response.data.length > 0) {
            response.data.map((product) => {
              return (product = ProductService.transformProduct(product));
            });
            return response.data;
          } else {
            return [];
          }
        })
      );
  }

  getBrandCategories() {
    return this.categorySource;
  }

  initBrandCategories(categories) {
    this.categorySource.next(categories);
  }

  getProductsByKatTwo(katTwo: string, filters?: any) {
    return this.getProductsByCat(katTwo, true, filters).pipe(
      map((res) => {
        const products = this.sortProducts(res.data, "ascending", "stock");
        return {
          products,
          pagination: res.pageData ?? {},
        };
      })
    );
  }

  public getProductsByCategory(
    category: string,
    products: Observable<Product[]>
  ): Observable<Product[]> {
    return products.pipe(
      map((items) => {
        return items.filter((item: any) => {
          if (item.KAT2) {
            return (
              item.KAT2.toLocaleLowerCase("tr-TR").replace(
                this.apiService.regEx,
                "_"
              ) ===
              category
                .toLocaleLowerCase("tr-TR")
                .replace(this.apiService.regEx, "_")
            );
          }
        });
      })
    );
  }

  // Get Products By Slug
  public getProductBySlug(slug: string, user?): Observable<Product> {
    return this.apiService
      .post(`productsByCode`, {
        method: "getProductByCode",
        route: "product",
        data: {
          code: this.localUpperCase(slug),
          user: user ?? null,
        },
      })
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-shadow
        map((response) => {
          if (response.result === "OK") {
            return ProductService.transformProduct(response.data);
          }
          return null;
        })
      );
  }

  // Add to Wishlist
  public addToWishlist(product): any {
    const wishlistItem = state.wishlist.find((item) => item.id === product.id);
    if (!wishlistItem) {
      state.wishlist.push({
        ...product,
      });
    }
    this.toastService.success("Product has been added in wishlist.", "", {
      closeButton: true,
    });
    localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
    return true;
  }

  // Remove Wishlist items
  public removeWishlistItem(product: Product): any {
    const index = state.wishlist.indexOf(product);
    state.wishlist.splice(index, 1);
    localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
    return true;
  }

  // Add to Compare
  public addToCompare(product): any {
    const compareItem = state.compare.find((item) => item.id === product.id);
    if (!compareItem) {
      state.compare.push({
        ...product,
      });
    }
    this.toastService.success("Product has been added in compare.");
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true;
  }

  // Calculate Stock Counts

  // Remove Compare items
  public removeCompareItem(product: Product): any {
    const index = state.compare.indexOf(product);
    state.compare.splice(index, 1);
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true;
  }

  public getFiltered(params, products) {
    return this.apiService
      .post(`products`, {
        method: "getFilteredProducts",
        route: "product",
        data: params,
      })
      .pipe(
        map((resp) => {
          if (resp.status === "OK") {
            const filteredProducts = resp.data.products.map((p) => p.STOKNO);
            const filtered = [];

            const prod = products.filter((p) => {
              if (filteredProducts.includes(p.STOKNO)) {
                return true;
              }
            });
            return prod;
          }
          return [];
        })
      );
  }

  public filterProductsByBrand(brand: string): Observable<Product[]> {
    return this.apiService
      .post(`getProductsByBrand`, {
        method: "getProductsByBrand",
        route: "product",
        data: { user: this.currentUser ?? null, brand },
      })
      .pipe(
        map((response) => {
          if (!isNull(response.data) && !isEmpty(response.data)) {
            return response.data.map((product) => {
              return (product = ProductService.transformProduct(product));
            });
          } else {
            return [];
          }
        })
      );
    /* return this.productsCache.pipe(
       map((item) => {
         return item.filter((p: Product) => {
           return p.MARKA === brand;
         });
       })
     );*/
  }

  // Get Product Filter
  public filterProducts(products, brand, filters: any): Observable<Product[]> {
    // enforce brand product filter
    const filteredProducts = products.filter((item: Product) => {
      if (!brand.length || brand.length === 0) {
        return true;
      }
      return brand.includes(item.MARKA);
    });
    if (!filters.length || filters.length === 0) {
      return of(filteredProducts);
    }
    const f = [];
    filteredProducts.forEach((i) => {
      const c = this.arrayContainsArray(i.tags, filters);
      if (c) {
        f.push(i);
      }
    });
    return of(f);
  }

  filterProductsByBrands(params: {
    filters: any[];
    selectedBrand: any;
    filteredBrands: any[];
    filteredTags: any[];
  }): {
    tags;
    brands;
  } {
    const { filters, selectedBrand, filteredBrands, filteredTags } = params;
    let brands: Array<string> = selectedBrand.brand
      ? selectedBrand.brand.split(",")
      : [];
    brands = _.uniq(brands);
    filters.push(brands);
    // filterBrands = brands; // = brands;
    const brandTag = brands
      .map((b) => `Markalar:${b}`)
      .filter((x) => x != `Markalar:${x}`);
    const _tags = filteredTags;
    if (!selectedBrand.isChecked) {
      _tags.splice(_tags.indexOf(selectedBrand.checkedItem));
    }
    const tags = uniq([..._tags, ...brandTag]);
    return {
      tags,
      brands,
    };
  }

  public filterProductsByFeature(params: {
    selectedFilters: Map<any, any>;
    features: any;
    selectedFeatures: any[];
    level: any;
    tags: any[];
  }) {
    const {
      features,
      selectedFeatures,
      selectedFilters,
      tags,
      level = 0,
    } = params;
    const checked = features.added;
    const featureValue: string = Object.values(features)[1] as string;
    const featureKey = Object.keys(features)[1];
    selectedFilters.set(features.id, features);
    // let productsToFilter = [];
    // productsToFilter = this.all_products;
    const filterLevel = level;
    const innerFilterLevel = false;
    // reset products to brands if no feat
    if (checked) {
      if (selectedFeatures.length > 0) {
        selectedFeatures.map((item) => {
          item.lastAdded = false;
          if (featureKey === item.key) {
            item.specs.lastAdded = false;
          }
        });
      }
      selectedFeatures.push({
        ...features,
        tag: `${featureKey} : ${featureValue}`,
        tt: featureValue,
        key: featureKey,
        value: featureValue,
        filterLevel: level,
        lastAdded: true,
        specs: {
          name: featureKey,
          spec: featureValue,
          lastAdded: true,
          filterLevel: level,
          count:
            selectedFeatures.filter((item) => item.specs.name === featureKey)
              .length + 1,
        },
      });
      tags.push(`${featureKey} : ${featureValue}`);
    } else {
      // remove what was unchecked
      _.remove(selectedFeatures, (i) => i.id === features.id);
      _.remove(tags, (i) => i === `${featureKey} : ${featureValue}`);

      if (selectedFeatures.length > 0) {
        selectedFeatures[selectedFeatures.length - 1].specs.lastAdded = true;
        selectedFeatures[selectedFeatures.length - 1].specs.count =
          selectedFeatures.length - 1;
      }
    }
    return {
      tags,
      selectedFilters,
      selectedFeatures,
      filterLevel,
    };

    // if (!_.isEmpty(this.selectedFeatures)) {
    //   this.initSelectedFeatures();
    // } else {
    //   this.resetAllSelectedFeatures();
    // }
  }

  arrayContainsArray(superset: Array<any>, subset: Array<any>) {
    const filteredProducts = superset.filter((tags) => {
      if (!subset.length) {
        return true;
      }
      const Tags = subset.some((prev) => {
        // Match Tags
        if (tags) {
          if (tags.includes(prev)) {
            return prev;
          }
        }
      });
      return Tags;
    });
    return filteredProducts.length > 0;
  }

  getCategoryOrder(katTwoSlug: string) {
    return this.apiService.post(`categoryOrder`, {
      method: "getCategoryOrder",
      route: "product",
      data: { categoryTitleSlug: katTwoSlug },
    });
  }

  /*
   *  GEt all product features for selected products in the said category; eg category = NOTEBOOK; get all notebook features
   *  @returns an Observable array of features that are sorted according to the predefined arrangement
   * */
  public getProductFeatures(
    allProducts,
    categoryTitle: string,
    preferredOrder?: any[]
  ): Observable<any[]> {
    const filterProductsByKatTwo = allProducts.filter((item) => {
      if (item.KAT2) {
        // eslint-disable-next-line max-len
        return (
          item.KAT2.toLocaleLowerCase("tr-TR").replace(
            this.apiService.regEx,
            "_"
          ) ===
          categoryTitle
            .toLocaleLowerCase("tr-TR")
            .replace(this.apiService.regEx, "_")
        );
      }
    });
    const productWithFeatures = [];
    let tt;
    const snSpec = [];

    filterProductsByKatTwo.forEach((product) => {
      if (product.features.length > 0) {
        // loop through features
        product.features.forEach((fd) => {
          if (!snSpec.includes(fd.name)) {
            snSpec.push(fd.name);
            tt = {
              name: fd.name,
              features: [fd.spec],
              id: product.STOKNO + "-" + fd.name,
            };
            productWithFeatures.push(tt);
          } else {
            productWithFeatures.map((feature) => {
              if (feature.name === fd.name) {
                if (!feature.features.includes(fd.spec)) {
                  feature.features.push(fd.spec);
                }
                return feature;
              }
            });
          }
        });
      }
    });
    productWithFeatures.map((data) => {
      data.features = data.features.sort(this.sortAlphaNum);
      return data;
    });
    console.log(productWithFeatures);

    // const a = fp.sort(this.sortFunc);

    const sorted = this.helperService.arrangeCategoryItems(
      categoryTitle,
      productWithFeatures,
      preferredOrder
    );

    return of(sorted);
  }

  sortAlphaNum(a, b) {
    const reA = /[^a-zA-Z]/g;
    const reN = /[^0-9]/g;
    const aA = a.replace(reA, "");
    const bA = b.replace(reA, "");
    if (aA === bA) {
      const aN = parseInt(a.replace(reN, ""), 10);
      const bN = parseInt(b.replace(reN, ""), 10);
      return aN === bN ? 0 : aN > bN ? 1 : -1;
    } else {
      return aA > bA ? 1 : -1;
    }
  }

  public showProductsInStock(products: Product[], payload: boolean): any {
    return payload ? products.filter((item) => item.stock > 0) : products;
  }

  public sortProducts(
    products: Product[],
    payload: string,
    sortType: string
  ): any {
    if (payload === "ascending") {
      return ProductService.sortByStok(products, "high-low-stok");
    }

    switch (sortType) {
      case "name":
        return ProductService.sortByName(products, payload);
      case "price":
        return ProductService.sortByPrice(products, payload);
      case "stock":
        return ProductService.sortByStok(products, payload);
      default:
        return products;
    }
  }

  public getPager(
    totalItems: number,
    currentPage: number = 1,
    pageSize: number = 16
  ) {
    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);

    // Paginate Range
    const paginateRange = 3;

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    // eslint-disable-next-line one-var
    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage < paginateRange - 1) {
      startPage = 1;
      endPage = startPage + paginateRange - 1;
    } else {
      startPage = currentPage - 1;
      endPage = currentPage + 1;
    }

    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    const pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      (i) => startPage + i
    );

    // return object with all pager properties required by the view
    return {
      totalItems,
      currentPage,
      pageSize,
      totalPages,
      startPage,
      endPage,
      startIndex,
      endIndex,
      pages,
    };
  }

  private filterProductsByCategory(products, category): Array<Product> {
    return products.filter((item: any) => {
      if (item.KAT1) {
        return (
          this.localUpperCase(item.KAT1).replace(this.apiService.regEx, "") ===
          category.replace(this.apiService.regEx, "")
        );
      }
    });
  }

  private getItem<T = any>(key: string): Promise<T> {
    return this.ngf.getItem<T>(key);
  }

  private getCachedItem<T = any>(key: string): Promise<T | null> {
    return this.cache.getCached<T>(key).then((r: CachedItem<T>) => {
      if (!r.hasData) {
        this.products.subscribe();
        return null;
      }
      if (r.expired) {
        // this.getAllProducts();
        const productsCache$ = this.products.pipe(shareReplay(CACHE_SIZE));
        productsCache$.subscribe();
        this.productsCache$ = productsCache$;
      }
      this.apiService.broadCastProducts(r.data);
      return r.data;
    });
  }

  // Sorting Filter

  private setCacheItem<T = any>(key: string, products) {
    this.cache
      .setCached<T>(key, products, 15000)
      .then((e) => {})
      .catch((e) => {
        console.log(e);
      });
  }

  productInCache(key: string) {
    return this.cache.getCached(key);
  }

  private getProductsByCat(category, katTypeTwo?: boolean, filters?: any) {
    return this.apiService
      .post(`getProductsByCategory`, {
        method: "getProductsByCategory",
        route: "product",
        data: { user: this.currentUser ?? null, katTypeTwo, filters },
        category, // .replace(this.apiService.regEx, ' ')
      })
      .pipe(
        map((res) => {
          if (!isNull(res)) {
            res.data.map((product) => {
              if (product.STOKNO) {
                return (product = ProductService.transformProduct(product));
              }
            });
            // for order components
            if (isEmpty(filters)) {
              // this.setCacheItem(category, res.data);
            }
            return res;
          }
        })
      );
  }

  private fetchProduct() {
    this.apiService
      .post(`products`, {
        method: "getProducts",
        route: "product",
        data: { user: this.currentUser },
      })
      .pipe(
        take(1),
        // eslint-disable-next-line @typescript-eslint/no-shadow
        map((response) => {
          if (response && response.result === "OK") {
            response.data.map((product) => {
              if (product.STOKNO) {
                return ProductService.transformProduct(product);
              }
            });
            // for order components
            this.setCacheItem("products", response.data);
            return response.data;
          }
          return [];
        })
      )
      .subscribe((data) => {
        this.productsCache.next(data);
      });
  }

  getProductFeaturesByCategory(selectedCategory, categoryOne) {
    return this.apiService.post(`productsFeatures`, {
      method: "productFeaturesByCategory",
      route: "product",
      category: selectedCategory,
      data: { user: this.currentUser, category: selectedCategory, categoryOne },
    });
  }

  getBrandsByKat2(selectedCategory, categoryOne) {
    return this.apiService.post(`brands`, {
      method: "getBrandsByKat2",
      data: { category: selectedCategory, categoryOne: categoryOne },
      route: "product",
    });
  }
}
