<div class="collection-collapse-block" [class.open]="collapse" *ngIf="productFeatures.length">
  <h3 class="collapse-block-title" (click)="collapse = !collapse">{{ featureTitle }}</h3>
  <div class="collection-collapse-block-content">
    <div class="collection-brand-filter">
      <div class="custom-control custom-checkbox collection-filter-checkbox" *ngFor="let feature of productFeatures; let i = index">
        <!-- [disabled]="disabled(feature)"-->
        <input type="checkbox" [value]="feature + '!' + featureId" [checked]="checked(feature + '!' + featureId)" [id]="feature + '!' + featureId" (change)="appliedFilter($event, i + feature + '!' + featureTitle)" class="custom-control-input" />
        <label class="custom-control-label" [for]="feature + '!' + featureId"
          >{{ feature }}
          <!--<span *ngIf="disabled(feature)"
                                              class="text-danger ml-2">{{ disabled(feature) ? '(0)' : '' }}</span>-->
        </label>
      </div>
    </div>
  </div>
</div>
