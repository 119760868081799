<!--<ng-template class="theme-modal" #ratioModal let-modal>
  <div class="modal-content">
    <div class="modal-header">
      &lt;!&ndash;      <h5 class="modal-title" id="exampleModalLabel">{{ product?.title }}</h5>&ndash;&gt;
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row" *ngIf="ratios">
        <div class="col-4 card" *ngFor="let r of ratios.data">
          <img class="card-image" src="{{ r.bank_logo }}" alt="{{ r.bank_name }}" />
          <div class="table-responsive">
            <table class="table table-striped" aria-labelledby="ratio table">
              <thead>
                <tr>
                  <th scope="col">Taksit</th>
                  <th scope="col">Taksit Tutarı</th>
                  <th scope="col">Tutar</th>
                </tr>
              </thead>
              <tr *ngFor="let rate of r['ratio']">
                <td>{{ $any(rate.desc) }}</td>
                <td>
                  <ng-container *ngIf="rate.desc === 'Tek Çekim'; else otherRate"> </ng-container>
                  <ng-template #otherRate> {{ rate.instalment }} x {{ rate.instalment_amount }} TL</ng-template>
                </td>
                <ng-container *ngIf="ratios?.addcommission_to_amount; else noAddCommission">
                  <td>{{ $any((rate.total_amount + rate.commision).toFixed(2)) }} TL</td>
                </ng-container>
                <ng-template #noAddCommission>
                  <td>{{ $any(rate.total_amount) }} TL</td>
                </ng-template>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>-->
<div class="navbar row">
  <div id="mySidenav" class="sidenav sidebar-ratio col-md-6" [class.openSide]="productService.openSideRatio.value">
    <a href="javascript:void(0)" class="sidebar-overlay" (click)="productService.openSideRatio.next(false)"></a>
    <nav id="unsetTwo">
      <div class="flex-shrink-0 pr-3 pt-3 pl-4 pb-0 bg-white">
        <div (click)="productService.openSideRatio.next(false)" class="d-flex align-items-center pb-3 mb-0 link-dark text-decoration-none">
          <div class="sidebar-back text-left cursor-pointer"><i class="fa fa-long-arrow-left pr-2" aria-hidden="true"></i> Geri</div>
        </div>
      </div>
      <div class="container pr-4 pb-4 pl-4 pt-1 mt-0 bg-white">
        <div class="row justify-content-around" *ngIf="ratios">
          <div class="col-sm-12 col-md-6 mb-3" *ngFor="let r of ratios.data">
            <div class="card p-2">
              <img class="card-image" src="{{ r.bank_logo }}" alt="{{ r.bank_name }}" />
              <div class="table-responsive">
                <table class="table table-striped" aria-labelledby="ratio table">
                  <thead>
                    <tr>
                      <th scope="col">Taksit</th>
                      <th scope="col">Taksit Tutarı</th>
                      <th scope="col">Tutar</th>
                    </tr>
                  </thead>
                  <tr *ngFor="let rate of r['ratio']">
                    <td>{{ $any(rate.desc) }}</td>
                    <td>
                      <ng-container *ngIf="rate.desc === 'Tek Çekim'; else otherRate"></ng-container>
                      <ng-template #otherRate> {{ rate.instalment }} x {{ rate.instalment_amount }} TL</ng-template>
                    </td>
                    <ng-container *ngIf="ratios?.addcommission_to_amount; else noAddCommission">
                      <td>{{ $any((rate.total_amount + rate.commision).toFixed(2)) }} TL</td>
                    </ng-container>
                    <ng-template #noAddCommission>
                      <td>{{ $any(rate.total_amount) }} TL</td>
                    </ng-template>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</div>
