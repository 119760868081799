import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccountService } from '../../../../services';
import { ProductService } from '../../../../services/product.service';
import { PRODUCT_DETAIL_PATH } from '../../../../interfaces/interface';

@Component({
  selector: 'app-modal-product-views',
  templateUrl: './modal-product-views.component.html',
  styleUrls: ['./modal-product-views.component.scss'],
})
export class ModalProductViewsComponent implements OnInit {
  @Input() layoutView;
  @Input() grid;
  @Input() products;
  @Input() currency;
  @Input() inStockOnly;
  @Output() selectProduct = new EventEmitter();
  public user: any;
  productDetailPage = PRODUCT_DETAIL_PATH;

  constructor(private accountService: AccountService, public productService: ProductService) {
    this.accountService
      .checkUser()
      .then((user) => {
        this.user = user;
      })
      .catch((e) => {
        this.user = null;
      });
  }

  ngOnInit(): void {}
}
