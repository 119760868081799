import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Product } from "../../classes/product";
import { orderBy } from "lodash";

@Component({
  selector: "app-brands",
  templateUrl: "./brands.component.html",
  styleUrls: ["./brands.component.scss"],
})
export class BrandsComponent implements OnInit {
  @Input() products: Product[] = [];
  @Input() brands: any[] = [];
  @Input() categoryBrands: any[] = [];

  @Output() brandsFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() customFilter?: EventEmitter<any> = new EventEmitter<any>();

  public collapse = true;

  constructor() {}

  get filterbyBrand() {
    const uniqueBrands = [];
    this.products.filter((product) => {
      if (product.MARKA) {
        const index = uniqueBrands.indexOf(product.MARKA);
        if (index === -1) {
          uniqueBrands.push(product.MARKA);
        }
      }
    });
    return orderBy(uniqueBrands);
  }

  ngOnInit(): void {}

  appliedFilter(event) {
    const index = this.brands.indexOf(event.target.value); // checked and unchecked value
    if (event.target.checked) {
      this.brands.push(event.target.value);
    } // push in array cheked value
    else {
      this.brands.splice(index, 1);
    } // removed in array unchecked value

    const brands = this.brands.length
      ? {
          brand: this.brands.join(","),
          isChecked: event.target.checked,
          checkedValue: event.target.value,
        }
      : { brand: null, isChecked: false };
    this.brandsFilter.emit(brands);
    if (this.customFilter) {
      this.customFilter.emit({
        brands,
        checkedItem: event.target.value,
        isChecked: event.target.checked,
      });
    }
  }

  // check if the item are selected
  checked(item) {
    if (this.brands.indexOf(item) !== -1) {
      return true;
    }
  }
}
