import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SiteSettingService } from '../../shared/services/site-setting.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-corporate',
  templateUrl: './corporate.component.html',
  styleUrls: ['./corporate.component.scss'],
})
export class CorporateComponent implements OnInit {
  public contentType;
  content: Observable<any>;

  constructor(private route: ActivatedRoute, private siteSettingService: SiteSettingService) {
    this.route.paramMap.subscribe((path) => {
      this.contentType = path.get('type');
      this.getData(this.contentType);
    });
  }

  getData(contentType: string) {
    this.content = this.siteSettingService.getCorporateData(contentType);
  }

  ngOnInit(): void {}
}
