import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavService } from '../../services/nav.service';

@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss'],
})
export class MobileHeaderComponent implements OnInit {
  @Input() greetings: string;
  themeLogo = 'assets/images/logos/logo-200.png'; // Default Logo

  @Input() user: any;
  @Input() class: any;
  @Input() sticky: any;
  @Output() logOut: EventEmitter<any> = new EventEmitter();
  cartItems: any[] = [];
  public stick = false;

  constructor(public navService: NavService) {}

  ngOnInit(): void {}

  navigate() {}
}
