// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const firebaseConfigDev = {
  apiKey: "AIzaSyA3YmHGjCexboblAinIz3SYhbQnspVaz3M",

  authDomain: "asbil-4e75d.firebaseapp.com",

  projectId: "asbil-4e75d",

  storageBucket: "asbil-4e75d.appspot.com",

  messagingSenderId: "708446768081",

  appId: "1:708446768081:web:89eecdf187c54b45972ef0",

  measurementId: "G-1MQQG4PHKJ",
};
const firebaseConfigProd = {
  apiKey: "AIzaSyCZ117BvHhPvcmK_jycSGHpcPZVxWUd8UA",
  authDomain: "asbil-online.firebaseapp.com",
  projectId: "asbil-online",
  storageBucket: "asbil-online.appspot.com",
  messagingSenderId: "264265343191",
  appId: "1:264265343191:web:86a5616bb783ba09421092",
  measurementId: "${config.measurementId}",
};

const isDev = false;
const apiUrlTest = "http://localhost/localphpserver/asbil-api/router.php";
let apiUrl = "http://localhost/localphpserver/asbil-api/router.php";
let apiUrlRoot = "http://localhost/localphpserver/asbil-api/"; //

if (isDev) {
  apiUrl = "http://localhost/localphpserver/asbil-api/router.php";
  apiUrlRoot = "http://localhost/localphpserver/asbil-api/"; //
} else {
  apiUrl = "https://app.asbilonline.com/api/apiV2/router.php"; // 'https://api.asbilonline.com/apiV2/router.php',
  apiUrlRoot = "https://app.asbilonline.com/api/apiV2/"; //'https://api.asbilonline.com/apiV2/',
}

export const environment = {
  production: false,
  instagram_token: "INSTA_TOKEN",
  stripe_token: "STRIPE_TOKEN",
  paypal_token: "PAYPAL_TOKEN",
  firebaseConfig: {
    apiKey: "AIzaSyCZ117BvHhPvcmK_jycSGHpcPZVxWUd8UA",
    authDomain: "asbil-online.firebaseapp.com",
    projectId: "asbil-online",
    storageBucket: "asbil-online.appspot.com",
    messagingSenderId: "264265343191",
    appId: "1:264265343191:web:86a5616bb783ba09421092",
    measurementId: "${config.measurementId}",
  },
  apiUrlTest,
  apiUrl,
  apiUrlRoot,
  isDevMode: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import "zone.js/plugins/zone-error"; // Included with Angular CLI.
