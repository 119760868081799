import { Injectable } from "@angular/core";

import { Router } from "@angular/router";
import { ApiUrl, UserType } from "../interface/interface";
import { HttpClient } from "@angular/common/http";
import { ApiService } from "./api.service";
import { catchError, take } from "rxjs/operators";
import { ErrorHandlerService } from "./error-handler.service";
import { AuthManagerService } from "./auth-manager.service";
import { environment } from "src/environments/environment";
import { map } from "rxjs/internal/operators";
import { throwError } from "rxjs";

const API_URL = ApiUrl;
const userType = UserType.admin;

@Injectable({
  providedIn: "root",
})
export class CustomersService {
  constructor(
    private http: HttpClient,
    private errorHandler: ErrorHandlerService,
    private authService: AuthManagerService,
    private router: Router,
    private apiService: ApiService
  ) {}

  getRegisteredUsers() {
    const userData = JSON.parse(localStorage.getItem(userType));

    const data = {
      method: "getRegisteredUsers",
      route: userType,
      data: userData,
      user: "admin",
    };
    return this.http.post(`${environment.apiUrl}/users`, data).pipe(
      take(1),
      map((response: any) => {
        return response.data;
      }),
      catchError(this.errorHandler.handleError)
    );
  }

  getAllVendors() {
    const userData = this.authService.userValue;

    const data = {
      method: "getAllVendors",
      route: userType,
      data: userData,
      user: "admin",
    };
    return this.http.post(`${environment.apiUrl}/users`, data).pipe(
      take(1),
      map((resp: any) => {
        return resp;
      }),
      catchError(this.errorHandler.handleError)
    );
  }

  getVendor(id) {
    const userData = this.authService.userValue;

    const data = {
      method: "getVendor",
      route: userType,
      data: { id },
      user: userData,
    };
    return this.http.post(`${environment.apiUrl}/users`, data).pipe(
      take(1),
      map((resp: any) => {
        if (resp?.result === "OK") {
          return resp.data;
        }
        return [];
      }),
      catchError(this.errorHandler.handleError)
    );
  }

  getUserTransaction(email: string) {
    const userData = JSON.parse(localStorage.getItem(userType));
    const data = {
      method: "getUserTransaction",
      route: userType,
      email,
      data: userData,
    };
    // return this.http
    //     .post(`${environment.apiUrl}/users`, data, this.requestOptions())
    //     .map((response: Response) => response.json());
  }

  deleteSelectedUser(user: any) {
    const data = {
      method: "deleteSelectedUser",
      route: userType,
      data: user,
    };
    return this.http
      .post(`${environment.apiUrl}/users`, data)
      .pipe(map((response: Response) => response));
  }

  getTransactionDetail(orderNumber: number) {
    const userData = JSON.parse(localStorage.getItem(userType));
    const data = {
      method: "transactionDetail",
      route: userType,
      user: userType,
      data: userData,
      orderNumber,
    };
    // return this.http
    //     .post(`${environment.apiUrl}/order`, data, this.requestOptions())
    //     .map((response: Response) => response.json());
  }

  activateVendor(param: {
    id: number;
    refCode: string;
    isActivationRequest: any;
  }) {
    const userData = this.authService.userValue;

    if (!userData) {
      return throwError({ RESULT: "Invalid user" });
    }
    const data = {
      method: "approveVendor",
      route: "retailer",
      data: param,
      user: userData,
    };
    return this.http.post(`${environment.apiUrl}/users`, data).pipe(
      take(1),
      map((resp: any) => {
        return resp;
      }),
      catchError(this.errorHandler.handleError)
    );
  }

  deActivateVendor(param: { id: number; refCode: string }) {
    const userData = this.authService.userValue;

    const data = {
      method: "deActivate",
      route: userType,
      data: param,
      user: userData,
    };
    return this.http.post(`${environment.apiUrl}/users`, data).pipe(
      take(1),
      map((resp: any) => {
        return resp;
      }),
      catchError(this.errorHandler.handleError)
    );
  }

  changePassword(param: { password; id }) {
    const userData = this.authService.userValue;

    const data = {
      method: "changeVendorPassword",
      route: userType,
      data: param,
      user: userData,
    };
    return this.http.post(`${environment.apiUrl}/users`, data).pipe(
      take(1),
      map((resp: any) => {
        return resp;
      }),
      catchError(this.errorHandler.handleError)
    );
  }

  retailerData(refCode: any) {
    const userData = this.authService.userValue;

    const data = {
      method: "getRetailerFullData",
      route: userType,
      data: { refCode },
      user: userData,
    };
    return this.http.post(`${environment.apiUrl}/users`, data).pipe(
      take(1),
      map((resp: any) => {
        if (resp?.result === "OK") {
          return resp.data;
        }
        return [];
      }),
      catchError(this.errorHandler.handleError)
    );
  }

  updateVendor(value) {
    const userData = this.authService.userValue;
    console.log(value);
    const data = {
      method: "updateVendorData",
      route: userType,
      data: value,
      user: userData,
    };
    return this.apiService.post("vendor", data).pipe(
      map((resp: any) => {
        return resp.data;
      })
    );
  }
}
