import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { isEmpty, toInteger } from "lodash";
import { HelperService } from "../../services/helper.service";
import {
  CONVERSION_KEY,
  DELIVERY_FEE,
  MAX_PRICE_FOR_DELIVERY,
} from "../../interfaces/interface";
import { CartService } from "../../services/cart.service";
import { UserType } from "../../classes/user";

@Component({
  selector: "app-convert-total-price",
  templateUrl: "./convert-total-price.component.html",
  styleUrls: ["./convert-total-price.component.scss"],
})
export class ConvertTotalPriceComponent implements OnInit, OnChanges {
  @Input() TotalCartPrice: number;
  @Input() usdTotal;
  @Input() tlTotal;
  @Input() user;
  totalPrice = 0;

  constructor(private helperService: HelperService, cartService: CartService) {}

  ngOnInit(): void {
    this.helperService.deliveryFeeEvent$.subscribe((value) => {
      if (typeof value === "boolean") {
        this.getPriceData(value);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.usdTotal && changes.usdTotal.currentValue) {
      this.getPriceData();
    }
    if (changes.tlTotal && changes.tlTotal.currentValue) {
      this.getPriceData();
    }
  }

  getPriceData(addFee = true) {
    const conv =
      this.usdTotal *
        JSON.parse(localStorage.getItem(CONVERSION_KEY)).data.rate ?? 0;

    const totalWithOutDelivery = toInteger(conv + this.tlTotal);
    
    if (this.user && this.user.userType === UserType.retailer) {
      this.totalPrice = totalWithOutDelivery;
    } else {
      this.totalPrice = addFee
        ? totalWithOutDelivery > MAX_PRICE_FOR_DELIVERY
          ? totalWithOutDelivery
          : totalWithOutDelivery + DELIVERY_FEE
        : totalWithOutDelivery;
    }

    this.helperService.initDeliverFee(this.totalPrice, !addFee);
    // this.helperService.getCartTotalInTR(this.usdTotal, this.tlTotal);
  }
}
