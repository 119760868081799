<!--<ng-particles-->
<!--	[id]="id"-->
<!--	[options]="particlesOptions"-->
<!--	(particlesLoaded)="particlesLoaded($event)"-->
<!--	(particlesInit)="particlesInit($event)"-->
<!--&gt;</ng-particles>-->

<div class="page-wrapper">
	<div class="authentication-box">
		<div class="container">
			<div class="row align-items-center align-middle justify-content-center">
				<div class="col-md-5 p-0">
					<div class="card logo-card">
						<div class="site-logo">
							<img src="assets/images/asbil_logo.png" />
						</div>
					</div>
				</div>
			</div>
			<div class="row align-items-center align-middle justify-content-center">
				<div class="col-md-7 p-0">
					<div class="card tab2-card">
						<div class="card-body">
							<ngb-tabset class="tab-coupon mb-0">
								<ngb-tab>
									<ng-template ngbTabTitle
										><i class="icon-user mr-2"></i>Login</ng-template
									>
									<ng-template ngbTabContent>
										<div
											class="tab-pane fade active show"
											id="account"
											role="tabpanel"
											aria-labelledby="account-tab"
										>
											<form
												[formGroup]="loginForm"
												class="form-horizontal auth-form"
												novalidate=""
												(ngSubmit)="onSubmit()"
											>
												<div class="form-group">
													<input
														required=""
														name="login[email]"
														type="email"
														class="form-control"
														placeholder="Email"
														formControlName="email"
														id="exampleInputEmail1"
													/>
												</div>
												<div class="form-group">
													<input
														required=""
														name="login[password]"
														type="password"
														class="form-control"
														formControlName="password"
														placeholder="Password"
													/>
												</div>
												<div class="form-terms">
													<div class="custom-control custom-checkbox mr-sm-2">
														<input
															type="checkbox"
															class="custom-control-input"
															id="customControlAutosizing"
														/>
														<label
															class="custom-control-label"
															for="customControlAutosizing"
															>Remember me</label
														>
														<a
															href="javascript:void(0)"
															class="btn btn-default forgot-pass"
															>lost your password</a
														>
													</div>
												</div>
												<div class="form-button">
													<button class="btn btn-primary">Login</button>
												</div>
											</form>
										</div>
									</ng-template>
								</ngb-tab>
								<!--  <ngb-tab>
                                    <ng-template ngbTabTitle><i class="icon-unlock mr-2"></i> Register
                                        <ng-template ngbTabContent>
                                            <form [formGroup]="registerForm" 
                                                class="form-horizontal auth-form" novalidate>
                                                <div class="form-group">
                                                    <input required="" name="login[username]" type="email"
                                                        class="form-control" placeholder="Username"
                                                        id="exampleInputEmail12">
                                                </div>
                                                <div class="form-group">
                                                    <input required="" name="login[password]" type="password"
                                                        class="form-control" placeholder="Password">
                                                </div>

                                                <div class="form-button">
                                                    <button class="btn btn-primary" type="submit">Register</button>
                                                </div>

                                            </form>
                                        </ng-template>
                                    </ng-template>
                                </ngb-tab> -->
							</ngb-tabset>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-container *ngIf="showLoading">
	<div class="loader-wrapper">
		<div id="loaderr"></div>
	</div>
</ng-container>
