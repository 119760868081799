import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { QuickViewComponent } from '../../modal/quick-view/quick-view.component';
import { CartModalComponent } from '../../modal/cart-modal/cart-modal.component';
import { Product } from '../../../classes/product';
import { ProductService } from '../../../services/product.service';
import { PRODUCT_DETAIL_PATH } from '../../../interfaces/interface';
import { User, UserType } from '../../../classes/user';
import { ToastService } from '../../../services/toast.service';
import { CartService } from '../../../services/cart.service';

@Component({
  selector: 'app-product-box-one',
  templateUrl: './product-box-one.component.html',
  styleUrls: ['./product-box-one.component.scss'],
})
export class ProductBoxOneComponent implements OnInit, OnChanges {
  @Input() product: Product;
  @Input() layoutView: string;
  @Input() currency: any = this.productService.Currency; // Default Currency
  @Input() thumbnail = false; // Default False
  @Input() onHowerChangeImage = false; // Default False
  @Input() cartModal = true; // Default False
  @Input() loader = false;
  @Input() user;

  @ViewChild('quickView') QuickView: QuickViewComponent;
  @ViewChild('cartModal') CartModal: CartModalComponent;

  productDetailPath = PRODUCT_DETAIL_PATH;
  public ImageSrc = 'assets/images/product/placeholder.jpg';
  public isUser: boolean;
  public checkingUser = true;

  constructor(
    private productService: ProductService,
    private toastService: ToastService,
    private cartService: CartService
  ) {}

  ngOnInit(): void {
    if (this.loader) {
      setTimeout(() => {
        this.loader = false;
      }, 2000); // Skeleton Loader
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.user && changes.user.currentValue?.isAuthenticated) {
      this.isUser = true;
      // this.isUser.markAllAsTouched();
    } else {
      this.isUser = false;
    }
    this.checkingUser = false;
  }

  addToCart(product, content) {
    if (product.stock < 1) {
      this.toastService.errorToast('Cant add product');
      return;
    }

    const user = localStorage.getItem('auth');
    const userData: User = JSON.parse(user).user;
    let userType = UserType.guest;

    if (!userData) {
      this.cartService.addToCartTwo(product, 1, true);
      return;
    }
    if ('userType' in userData) {
      userType = userData.userType;
    }

    switch (userType) {
      case 'retailer':
        // cartModal = this.modalService.open(CartModalComponent);
        this.CartModal.openModal(CartModalComponent);
        // cartModal.componentInstance.component = product;
        break;
      case 'guest':
        this.cartService.addToCartTwo(product, 1, true);
        break;
      case 'customer':
        this.cartService.addToCartTwo(product, 1, true);
        break;
      default:
        this.cartService.addToCartTwo(product, 1, true);
    }
  }

  addToWishlist(product: any) {
    this.productService.addToWishlist(product);
  }

  addToCompare(product: any) {
    this.productService.addToCompare(product);
  }
}
