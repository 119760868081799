import { Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { Product } from '../../../classes/product';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isPlatformBrowser } from '@angular/common';
import { ProductService } from '../../../services/product.service';

@Component({
  selector: 'app-ratio-modal',
  templateUrl: './ratio-modal.component.html',
  styleUrls: ['./ratio-modal.component.scss'],
})
export class RatioModalComponent implements OnInit, OnDestroy {
  @Input() ratios: any;

  @ViewChild('ratioModal', { static: false }) RatioModal: TemplateRef<any>;

  public closeResult: string;
  public modalOpen = false;

  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(@Inject(PLATFORM_ID) private platformId: object, private modalService: NgbModal, public productService: ProductService) {}

  ngOnInit(): void {}

  openModal() {
    console.log(this.ratios);

    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) {
      // For SSR
      this.modalService
        .open(this.RatioModal, {
          size: 'xl',
          ariaLabelledBy: 'ratio-modal',
          centered: true,
          windowClass: 'RatioModal',
          scrollable: true,
        })
        .result.then(
          (result) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            `Result ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

  toggleRightMobileMenu() {}
}
