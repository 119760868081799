<ng-container *ngIf="product.discountPrice">
    <!--<div class="lable-block">
    <span class="product-tag discount-tag"
    >{{ product.discountPercentage }}%</span
    >
    font-size: 0.71rem;
    padding-left: 21px;
    top: 46%;
    left: -8px;
    </div>-->
<span  title="%{{ product?.discountPercentage }} İndirim" class=" ui ribbon label discount-ribbon asbil-left-[-0.4rem] asbil-absolute asbil-w-[65px] asbil-bg-secondary asbil-border-red-400">{{ product.discountPercentage }}%</span>
</ng-container>
<div class="asbil-card asbil-border asbil-m-1">

    <ng-container *ngIf="!loader">

        <a
            [routerLink]="['/shop/product/urun/', product?.STOKNO]"
            target="_blank"
            class="product-image-wrapper"
        >

            <div
                class="product-image-bg"
                [ngStyle]="{
					'background-image': 'url(' + product?.images[0].src + ')'
				}"
            ></div>
            <!--<img
      [src]="product?.images.length > 0 ? product?.images[0].src : ImageSrc"
      alt="{{ product.ADI }}"
      class="img-fluid lazy-loading card-img-top"
    />-->
        </a>

        <div class="product-detail card-body">
            <!--      <bar-rating [rate]="5" [readOnly]="true"></bar-rating>-->

            <!-- Grid view -->
            <div class="grid-view-content">
                <!--			<span class="badge badge-pill">{{ product?.MARKA | titlecase }}</span>-->
                <h6 class="product-name">
                    <a
                        [routerLink]="['/shop/product/urun/', product?.STOKNO]"
                        target="_blank"
                        class=""
                    >
                        {{ product?.WEBACIKLAMA }}
                    </a>
                </h6>
            </div>
        </div>
        <ng-container *ngIf="!checkingUser">
            <div class="card-footer">
                <div class="row justify-content-center align-items-center mb-2">
                    <div class="col-md-6">
                        <ng-container *ngIf="isUser; else userFailSafePrice">
                            <h4 class="price">
                                <ng-container *ngIf="product.discountPrice; else noDiscount">
                                    {{
                                    product?.discountPrice
                                    | currency: product?.currency:'symbol-narrow'
                                    }}
                                    <del *ngIf="product?.discountPrice"
                                    ><span class="money">
											{{
												product?.displayPrice
													| currency: product?.currency:'symbol'
											}}</span
                                    >
                                    </del>
                                </ng-container>
                                <ng-template #noDiscount>
                                    {{
                                    product?.displayPrice
                                    | currency: product?.currency:'symbol-narrow'
                                    }}
                                </ng-template>
                            </h4>
                        </ng-container>
                        <ng-template #userFailSafePrice>
                            <h4 class="price">
                                <ng-container *ngIf="product.discountPrice; else noDiscount">
                                    {{
                                    product?.discountPrice
                                    | currency: product?.currency:'symbol-narrow'
                                    }}
                                    <del *ngIf="product?.discountPrice"
                                    ><span class="money">
											{{
												product?.SATISFIYATI9
													| currency: product?.currency:'symbol'
											}}</span
                                    >
                                    </del>
                                </ng-container>
                                <ng-template #noDiscount>
                                    {{
                                    product?.SATISFIYATI3
                                    | currency: product?.currency:'symbol-narrow'
                                    }}
                                </ng-template>
                            </h4>
                        </ng-template>
                    </div>
                    <div class="col-md-6 text-right">
                        <span class="money"> Stok : {{ product?.stock }} </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <button
                            [disabled]="product?.stock <= 0"
                            (click)="addToCart(product, cartModal)"
                            class="btn btn-sm btn-outline-primary btn-block add-to-cart"
                        >
                            <!--CartModal.openModal(product)-->
                            Sepete Ekle <i class="fa fa-shopping-cart"></i>
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view
    #quickView
    [currency]="currency"
    [product]="product"
></app-quick-view>
<app-cart-modal
    #cartModal
    *ngIf="cartModal"
    [currency]="currency"
    [product]="product"
></app-cart-modal>
