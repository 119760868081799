<div class="modal-header">
  <h4 class="modal-title">{{ component }}</h4>
  <button type="button" class="close" aria-label="Close" ngbAutofocus (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body productsM" id="productsM">
  <section class="section-b-space pt-2 collection-wrapper">
    <div class="collection-wrapper">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-3">
            <div [class.openFilterbar]="mobileSidebar" class="collection-filter">
              <div class="collection-filter-block">
                <ng-container *ngIf="allProducts.length === 0">
                  <div class="row mt-4">
                    <div *ngFor="let c of [].constructor(15)" class="col-12">
                      <ngx-skeleton-loader animation="progress"></ngx-skeleton-loader>
                    </div>
                  </div>
                </ng-container>
                <app-brands (brandsFilter)="filterByBrand($event)" [brands]="brands" [products]="allProducts">
                </app-brands>

                <ng-container *ngFor="let feature of featureCategories; let x = index">
                  <app-features
                    (featuresFilter)="updateFilter($event)"
                    (customFilter)="customFilter($event, x, feature.name)"
                    [featureTitle]="feature.name"
                    [featureId]="feature.id"
                    [features]="features"
                    [productFeatures]="feature['features']"
                    id="{{ x }}"
                  >
                  </app-features>
                </ng-container>
              </div>

              <!-- side-bar banner end here -->
            </div>
          </div>
          <div class="collection-content col-lg-9">
            <div class="page-main-content ">
              <div class="row">
                <div class="col-sm-12">
                  <div class="collection-product-wrapper">
                    <app-grid
                      (setAvailable)="setAvailable($event)"
                      (setGrid)="updateGridLayout($event)"
                      (setLayout)="updateLayoutView($event)"
                      (sortedByName)="sortByName($event)"
                      (sortedByPrice)="sortByPrice($event)"
                      (sortedByStock)="sortByStock($event)"
                      [layoutView]="layoutView"
                      [products]="products"
                      [sortBy]="sortBy"
                      [showAOnlyAvailableStock]="showAOnlyAvailableStock"
                      [disableCheck]="true"
                    >
                    </app-grid>
                    <div [ngClass]="layoutView" class="product-wrapper-grid">
                      <div class="">
                        <app-modal-product-views
                          [currency]="'USD'"
                          [grid]="grid"
                          [layoutView]="layoutView"
                          [products]="products"
                          (selectProduct)="selectProduct($event)"

                        ></app-modal-product-views>

                        <div
                          *ngIf="!isLoading && products?.length == 0"
                          class="col-sm-12 text-center section-b-space mt-5 no-found"
                        >
                          <h3>Ürün yok!!!</h3>
                        </div>
                      </div>
                      <div class="row">
                        <ng-container *ngIf="isLoading">
                          <div *ngFor="let c of [].constructor(16)" [ngClass]="grid" class="col-grid-box">
                            <ngx-skeleton-loader animation="progress"></ngx-skeleton-loader>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss()">Kapat</button>
</div>
