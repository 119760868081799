import {
	AfterViewChecked,
	AfterViewInit,
	Component,
	ElementRef,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	PLATFORM_ID,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductPictureSlider, ProductThumbSlider } from '../../../data/slider';
import { Product } from '../../../classes/product';

@Component({
	selector: 'app-picture-modal',
	templateUrl: './picture-modal.component.html',
	styleUrls: ['./picture-modal.component.scss'],
})
export class PictureModalComponent implements OnInit, OnDestroy {
	@Input() product: Product;
	@ViewChild('pictureModal', { static: false }) PictureModal: TemplateRef<any>;
	@ViewChild('slider', { static: false }) slider: ElementRef<any>;

	public ProductDetailsMainSliderConfig: any = ProductPictureSlider;
	public ProductDetailsThumbConfig: any = ProductThumbSlider;
	public closeResult: string;
	public modalOpen = false;
	public activeSlide: any = 0;
	slideIndex = 1;

	// eslint-disable-next-line @typescript-eslint/ban-types
	constructor(
		@Inject(PLATFORM_ID) private platformId: Object,
		private modalService: NgbModal
	) {}

	ngOnInit(): void {}
	showSlides(n) {
		let i;
		const slidesWrapper = document.getElementsByClassName('wrapper');
		const s = slidesWrapper.item(0).getElementsByClassName('mySlides');
		console.log(s);
		const slides = s; // document.getElementsByClassName('mySlides');
		const dots = document.getElementsByClassName('demo');
		const captionText = document.getElementById('caption');
		if (n > slides.length) {
			this.slideIndex = 1;
		}
		if (n < 1) {
			this.slideIndex = slides.length;
		}
		for (i = 0; i < slides.length; i++) {
			slides[i].setAttribute('style', 'display:none'); // .style.display = 'none';
		}
		for (i = 0; i < dots.length; i++) {
			dots[i].className = dots[i].className.replace(' active', '');
		}
		slides[this.slideIndex - 1].setAttribute('style', 'display:block'); // .style.display = 'block';
		dots[this.slideIndex - 1].className += ' active';
		captionText.innerHTML = dots[this.slideIndex - 1].getAttribute('alt');
	}

	closeModal() {
		this.modalService.dismissAll();
	}

	openModal(product) {
		console.log(product);
		this.modalOpen = true;
		if (isPlatformBrowser(this.platformId)) {
			//  For SSR

			this.modalService
				.open(this.PictureModal, {
					size: 'lg',
					ariaLabelledBy: 'Picture-Modal',
					centered: true,
					windowClass: 'modal fade picture-modal',
				})
				.result.then(
					(result) => {
						console.log(`Result ${result}`);
					},
					(reason) => {
						this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
					}
				)
				.then(() => {});
			this.showSlides(this.slideIndex);
		}
	}

	ngOnDestroy() {
		if (this.modalOpen) {
			this.modalService.dismissAll();
		}
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}
}
