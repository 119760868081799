import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from '../shared/services/helper.service';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss'],
})
export class ShopComponent implements OnInit {
  public url: any;
  public themeLogo = 'assets/images/logos/logo_blue.png';

  constructor(private router: Router, private helper: HelperService) {
    this.helper.getSaveConversion().subscribe();
  }

  ngOnInit(): void {}
}
