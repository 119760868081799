import * as auth from './reducers/auth.reducer';
import * as cart from './reducers/cart.reducer';
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

export interface AppState {
  authState: auth.State;
  status: auth.State;
  cart: cart.State;
}

/*export const reducers = {
  auth: auth.reducer,
};*/
export const reducers: ActionReducerMap<any> = {
  auth: auth.reducer,
  cart: cart.reducer,
};
export const selectAuthState = createFeatureSelector<AppState>('auth');
export const selectCart = createFeatureSelector<AppState>('cart');

export const selectAuthStatusState = createSelector(selectAuthState, (state) => state.authState);

export const selectCartStatusState = createSelector(selectCart, (state) => state.cart);
