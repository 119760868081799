<!--footer section -->
<footer [class]="class">
  <section class="pt-4 pb-4">
    <div class="container">
      <div class="row footer-theme">
        <div class="col-12 d-flex">
          <div class="m-auto">
            <div class="footer-brand-logo">
              <a href="javascript:void(0)">
                <img [src]="themeLogo" class="img-fluid" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row footer-theme mt-5">
        <div class="col-sm-12 col-md-4">
          <h3 class="text-center">İRTİBAT - MAĞUSA</h3>
          <ul class="list-unstyled address-info">
            <li>
              <i class="fa fa-map-marker"></i>
              <span class="address">
                Eşref Bitlis Cad. Mesan Apt. C-Blok Dükkan-8, Serbest Liman
                Yolu, Gazimağusa – KKTC
              </span>
            </li>
            <li>
              <i class="fa fa-phone"></i>
              <span class="address"> +90 392 365 13 58-59 </span>
            </li>
            <li>
              <i class="fa fa-whatsapp"></i>
              <span class="address"> +90 548 850 98 88 </span>
            </li>
            <li>
              <i class="fa fa-envelope"></i>
              <span class="address"> info@asbilbilisim.com </span>
            </li>
          </ul>
        </div>
        <div class="col-sm-12 col-md-4">
          <h3 class="text-center">İRTİBAT - LEFKOŞA</h3>
          <ul class="list-unstyled address-info">
            <li>
              <i class="fa fa-map-marker"></i>
              <span class="address">
                Atatürk Caddesi, Candemir 7 Apt. No:26 - Dükkan A
                Yenişehir/Lefkoşa
              </span>
            </li>
            <li>
              <i class="fa fa-phone"></i>
              <span class="address"> +90 392 227 31 77 </span>
            </li>
            <li>
              <i class="fa fa-whatsapp"></i>
              <span class="address"> +90 548 850 98 90 </span>
            </li>
          </ul>
        </div>
        <div class="col-sm-12 col-md-4">
          <h3 class="text-center">ALIŞVERİŞ BİLGİLERİ</h3>
          <ul class="list-unstyled address-info">
            <li>
              <a routerLink="/shop/contact">
                <i class="fa fa-chevron-right"></i>
                <span class="address"> İletişim</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <div class="sub-footer">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 col-md-6 col-sm-12">
          <div class="footer-end">
            <p>
              Asbil Bilişim LTD.
              <i class="fa fa-copyright" aria-hidden="true"></i>
              {{ today | date: "y" }}. Her hakkı saklıdır.
            </p>
          </div>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-12">
          <div class="payment-card-bottom">
            <ul>
              <li>
                <a><img src="assets/images/icon/visa.png" alt="" /></a>
              </li>
              <li>
                <a><img src="assets/images/icon/mastercard.png" alt="" /></a>
              </li>
              <li>
                <a><img src="assets/images/icon/paypal.png" alt="" /></a>
              </li>
              <li>
                <a
                  ><img src="assets/images/icon/american-express.png" alt=""
                /></a>
              </li>
              <li>
                <a><img src="assets/images/icon/discover.png" alt="" /></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
<!--footer section end -->
