import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
// import {Container, Main} from 'ng-particles';
// import {} from 'src/app/shared/services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {AuthManagerService} from '../../../shared/service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    public loginForm: FormGroup;
    public registerForm: FormGroup;
    id = 'tsparticles';

    particlesOptions = {
        background: {
            color: {
                value: '#00b2c4', // '#0d47a1',
            },
        },
        fpsLimit: 70,
        interactivity: {
            detectsOn: 'canvas',
            events: {
                onClick: {
                    enable: true,
                    mode: 'push',
                },
                onHover: {
                    enable: true,
                    mode: 'repulse',
                },
                resize: true,
            },
            modes: {
                bubble: {
                    distance: 400,
                    duration: 2,
                    opacity: 0.8,
                    size: 40,
                },
                push: {
                    quantity: 4,
                },
                repulse: {
                    distance: 200,
                    duration: 0.4,
                },
            },
        },
        particles: {
            color: {
                value: '#ffffff',
            },
            links: {
                color: '#ffffff',
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1,
            },
            collisions: {
                enable: true,
            },
            move: {
                direction: 'none',
                enable: true,
                outMode: 'bounce',
                random: false,
                speed: 6,
                straight: false,
            },
            number: {
                density: {
                    enable: true,
                    value_area: 800,
                },
                value: 80,
            },
            opacity: {
                value: 0.5,
            },
            shape: {
                type: 'circle',
            },
            size: {
                random: true,
                value: 5,
            },
        },
        detectRetina: true,
    };
    public showLoading: boolean;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private authManager: AuthManagerService,
        private toastr: ToastrService
    ) {
        this.createLoginForm();
        this.createRegisterForm();
    }

    createLoginForm() {
        this.loginForm = this.formBuilder.group({
            email: ['', Validators.compose([Validators.required, Validators.email])],
            password: ['', Validators.compose([Validators.required])],
        });
    }

    createRegisterForm() {
        this.registerForm = this.formBuilder.group({
            email: ['', Validators.compose([Validators.required, Validators.email])],
            password: ['', Validators.compose([Validators.required])],
            confirmPassword: [''],
        });
    }

    ngOnInit() {
    }

    onSubmit() {
        if (this.loginForm.invalid) {
            this.toastr.error('Please enter your credentials');
            return;
        }
        this.showLoading = true;

        const user = {
            email: this.loginForm.controls.email.value,
            password: this.loginForm.controls.password.value,
            user: 'admin',
        };

        this.authManager.doLogin(user).subscribe((res) => {
            // console.log(res);
            this.showLoading = false;
            this.toastr.success('Logged in..');
            this.router.navigate(['admin/dashboard']).catch((e) => {
                console.log(e);
            });
        }, error => {
            this.showLoading = false;
            alert(error);
        });
    }

   /* particlesLoaded(container: Container): void {
        console.log(container);
    }

    particlesInit(main: Main): void {
        console.log(main);
        // Starting from 1.19.0 you can add custom presets or shape here, using the current tsParticles instance (main)
    }*/
}
