<div class="navbar">
  <a href="javascript:void(0)" (click)="toggleRightMobileMenu()">
    <div class="bar-style">
      <i class="fa fa-user-circle-o sidebar-bar" [class.loggedIn]="user" aria-hidden="true"></i>
    </div>
  </a>
  <div id="mySidenav" class="sidenav sidebar-account" [class.openSide]="navService?.rightMobileMenuToggle">
    <a
      href="javascript:void(0)"
      class="sidebar-overlay"
      (click)="navService.rightMobileMenuToggle = false"
      (mouseover)="onHover(false)"
    ></a>
    <nav id="unsetTwo">
      <div class="flex-shrink-0 p-3 bg-white">
        <div
          (click)="toggleRightMobileMenu()"
          class="d-flex align-items-center pb-3 mb-3 link-dark text-decoration-none"
        >
          <div class="sidebar-back text-left"><i class="fa fa-long-arrow-left pr-2" aria-hidden="true"></i> Geri</div>
        </div>
        <ul class="list-unstyled ps-0">
          <li class="mb-1" *ngIf="user">
            <button
              (click)="navigateUserAccount()"
              class="btn btn-toggle align-items-center rounded collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#orders-collapse"
              aria-expanded="false"
            >
              <span>Hesabim</span>
            </button>
          </li>
          <ng-container *ngIf="!user">
            <li class="mb-1">
              <button class="btn btn-toggle align-items-center rounded" (click)="navigate('/auth/uye-giris')">
                Üye Giriş
              </button>
            </li>
            <li class="mb-1">
              <button class="btn btn-toggle align-items-center rounded" (click)="navigate('/auth/uye-ol')">
                Üye Ol
              </button>
            </li>
            <li class="mb-1">
              <a (click)="navigate('/auth/bayi-giris')" class="btn btn-toggle align-items-center rounded">
                Bayi Girisi/Ol
              </a>
            </li>
          </ng-container>

          <li class="mb-1">
            <button class="btn btn-toggle align-items-center rounded" (click)="navigate('/shop/cart')">
              <span class="">Sepetim</span>
              <span class="cart_qty_cls">{{ cartItems?.length }}</span>
            </button>
          </li>
          <li class="border-top my-3"></li>
          <li class="mb-1">
            <button
              class="btn btn-toggle align-items-center rounded collapsed"
              (click)="logOut()"
              aria-expanded="false"
            >
              Cikis Yap
            </button>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>
