import {EventEmitter, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {NavigationStart, Router} from '@angular/router';

import {HttpClient} from '@angular/common/http';
import {catchError, map, share} from 'rxjs/operators';
import {ApiService, userType} from './api.service';
import {ErrorHandlerService} from './error-handler.service';
import {Admin} from '../classes/user';
import {environment} from 'src/environments/environment';


@Injectable({
    providedIn: 'root',
})
export class AuthManagerService {
    // user: any;
    redirectUrl;
    showLoading = true;
    public userLoggedIn: boolean;
    public user: Observable<Admin>;
    private isLoginSubject = new BehaviorSubject<boolean>(this.hasToken()); // variable to keep track of
    // user status
    private keepAfterNavigationChange = false;
    private userGreetings: EventEmitter<any> = new EventEmitter();
    private userSubject: BehaviorSubject<Admin>;

    constructor(
        private http: HttpClient,
        private router: Router,
        private apiService: ApiService,
        private errorHandlerService: ErrorHandlerService
    ) {
        // clear alert message on route change
        router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                // keep checking after page navigation//
                // this helps control unauthorised access.
                //
                this.isLoginSubject.next(this.hasToken());
            }
        });
        this.userSubject = new BehaviorSubject<Admin>(JSON.parse(localStorage.getItem(userType)));
        this.user = this.userSubject.asObservable();
    }

    public get userValue(): Admin {
        return this.userSubject.value;
    }

    getUser(user: any): Observable<any> {
        console.log(user);
        const data = {
            data: user,
            route: userType,
            user: userType,
            method: 'validate',
        };

        return this.http.post(`${environment.apiUrl}/user`, data).pipe(map((resp: any) => {
            return resp.user;
        }), catchError(this.errorHandlerService.handleError));
        // .pipe(map((response: Response) => response.json()));
    }

    doLogin(user: {
        email: string;
        password: string;
        user: string;
    }): Observable<any> {
        const data = {
            method: 'login',
            route: userType,
            data: user,
        };
        return this.http.post(`${environment.apiUrl}/login`, data).pipe(
            map((response: any) => this.saveUserData(response.user)),
            catchError(this.errorHandlerService.handleError)
        );
        // .pipe(map((response: Response) => response.json()));
    }

    recoverPassword(data): Observable<any> {
        return this.http.post(`${environment.apiUrl}/recover`, data);
    }

    setLogInStatus(status: boolean, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.isLoginSubject.next(status);
    }

    updateUserProfile(values: {
        name: string;
        address: string;
    }): Observable<any> {
        const data = {
            method: 'updateProfile',
            route: userType,
            data: values,
        };

        return this.http.post(`${environment.apiUrl}/update`, data).pipe(
            map((response) => {
                console.log(response);
                return response;
            }),
            catchError(this.errorHandlerService.handleError)
        );
    }

    changePassword(values: any): Observable<any> {
        const data = {
            method: 'changePassword',
            route: userType,
            data: values,
        };

        return this.http.post(`${environment.apiUrl}/change`, data).pipe(
            // map((response: Response) => response.json()),
            catchError(this.errorHandlerService.handleError)
        );
    }

    /**
     * @returns Observable
	 **/

    getLoginStatus(): Observable<any> {
        return this.isLoginSubject.asObservable().pipe(share());
    }

    logOut(): void {
        localStorage.removeItem(userType);
        localStorage.removeItem('token');
        this.router
            .navigate(['/auth/login'])
            .then(() => {
                this.isLoginSubject.next(false);
            })
            .catch((err) => console.log(err));
    }

    saveUserData(user) {
        this.userSubject.next(user);
        localStorage.setItem(userType, JSON.stringify(user));
        return user;
        // this.localForageService.set(userType, result);
    }

    /**
     * if we have a token the user is logged in
     * @return boolean
     * */
    private hasToken(): boolean {
        return !!localStorage.getItem(userType);
    }

}
