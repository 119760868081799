<h4 class="mb-3">{{ title }}</h4>
<form class="needs-validation" novalidate [formGroup]="addressForm" [autocomplete]="'off'">
  <div class="row">
    <div class="col-md-6 mb-3">
      <label for="firstName">ADI</label>
      <input
        type="text"
        class="form-control"
        id="firstName"
        name="firstName"
        placeholder=""
        value=""
        required=""
        formControlName="firstname"
      />
      <div
        class="invalid-feedback"
        *ngIf="
          addressForm.controls['firstname'].invalid &&
          (addressForm.controls['firstname'].dirty || addressForm.controls['firstname'].touched)
        "
      >
        Please enter a first name
      </div>
    </div>
    <div class="col-md-6 mb-3">
      <label for="lastName">SOYADI</label>
      <input
        type="text"
        class="form-control"
        name="lastName"
        id="lastName"
        autocomplete="off"
        required
        formControlName="lastname"
      />
      <div
        class="invalid-feedback"
        *ngIf="
          addressForm.controls['lastname'].invalid &&
          (addressForm.controls['lastname'].dirty || addressForm.controls['lastname'].touched)
        "
      >
        Please enter a surname
      </div>
    </div>
  </div>

  <div class="mb-3">
    <label for="email">E-POSTA </label>
    <input
      type="email"
      class="form-control"
      name="email"
      id="email"
      placeholder="you@example.com"
      autocomplete="off"
      required
      formControlName="email"
    />
    <div
      class="invalid-feedback"
      *ngIf="
        addressForm.controls['email'].invalid &&
        (addressForm.controls['email'].dirty || addressForm.controls['email'].touched)
      "
    >
      Please enter an email address
    </div>
  </div>
  <div class="mb-3">
    <label for="email">Phone </label>
    <input
      type="text"
      class="form-control"
      id="phone"
      name="phone"
      required
      autocomplete="off"
      formControlName="phone"
    />
    <div
      class="invalid-feedback"
      *ngIf="
        addressForm.controls['phone'].invalid &&
        (addressForm.controls['phone'].dirty || addressForm.controls['phone'].touched)
      "
    >
      Please enter a phone number
    </div>
  </div>

  <div class="mb-3">
    <label for="address">ADRES</label>
    <input
      type="text"
      class="form-control"
      id="address"
      placeholder="ADRES"
      required
      name="address"
      formControlName="address"
    />
    <div
      class="invalid-feedback"
      *ngIf="
        addressForm.controls['address'].invalid &&
        (addressForm.controls['address'].dirty || addressForm.controls['address'].touched)
      "
    >
      Please enter an address
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 mb-3 district-type-ahead">
      <label for="city">İl seçiniz</label>
      <select
        formControlName="city"
        id="city"
        class="form-select custom-select"
        aria-label="Default select example"
        name="city"
        required
        (change)="citySelected($event)"
      >
        <option value="" disabled>....</option>

        <ng-container *ngFor="let city of cities">
          <option>{{ city.name }}</option>
        </ng-container>
      </select>
      <div
        class="invalid-feedback"
        *ngIf="
          addressForm.controls['city'].invalid &&
          (addressForm.controls['city'].dirty || addressForm.controls['city'].touched)
        "
      >
        Please select a valid district
      </div>
    </div>
    <div class="col-md-6 mb-3 district-type-ahead">
      <label for="district">İlçe Seçiniz</label>
      <select
        formControlName="district"
        id="district"
        name="district"
        class="form-select custom-select"
        aria-label="Default select example"
        required
        (change)="districtSelected($event)"
      >
        <option value="" disabled>....</option>

        <ng-container *ngFor="let district of districts">
          <option>{{ district.name }}</option>
        </ng-container>
      </select>

      <div
        class="invalid-feedback"
        *ngIf="
          addressForm.controls['district'].invalid &&
          (addressForm.controls['district'].dirty || addressForm.controls['district'].touched)
        "
      >
        <!--                                Please select a valid state.-->
      </div>
    </div>
  </div>
  <hr class="mb-4" />

  <div class="custom-control custom-checkbox mb-3" *ngIf="type === 'shipping'">
    <input type="checkbox" class="custom-control-input" id="same-address" formControlName="sameAddress" />
    <label class="custom-control-label" for="same-address">Fatura adresim kargo adresim ile aynı </label>
  </div>

  <button class="btn btn-primary btn-lg btn-block" (click)="saveAddress()">
    <ng-container *ngIf="type === 'billing'">Ödeme Adımına</ng-container>
    Devam Et
  </button>
</form>

<!--<div class="row">

                             <div class="col-lg-6 col-sm-12 col-xs-12">
                                 <div class="checkout-title">
                                     <h3>Billing Details</h3>
                                 </div>
                                 <div class="row check-out">
                                     <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                         <div class="field-label">First Name</div>
                                         <input [formControl]="addressForm.controls['firstname']" autocomplete="off"
                                                name="firstname" placeholder=""
                                                type="text" value="">
                                         <div *ngIf="addressForm.controls.firstname.touched && addressForm.controls.firstname.errors?.required"
                                              class="text text-danger">
                                             First Name is required.
                                         </div>
                                         <div *ngIf="addressForm.controls.firstname.touched && addressForm.controls.firstname.errors?.pattern"
                                              class="text text-danger">
                                             First Name must be an alphabates.
                                         </div>
                                     </div>
                                     <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                         <div class="field-label">Last Name</div>
                                         <input [formControl]="addressForm.controls['lastname']" autocomplete="off"
                                                name="lastname" placeholder=""
                                                type="text" value="">
                                         <div *ngIf="addressForm.controls.lastname.touched && addressForm.controls.lastname.errors?.required"
                                              class="text text-danger">
                                             Last Name is required.
                                         </div>
                                         <div *ngIf="addressForm.controls.lastname.touched && addressForm.controls.lastname.errors?.pattern"
                                              class="text text-danger">
                                             Last Name must be an alphabates.
                                         </div>
                                     </div>
                                     <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                         <div class="field-label">Phone</div>
                                         <input [formControl]="addressForm.controls['phone']" autocomplete="off"
                                                name="phone" placeholder=""
                                                type="text" value="">
                                         <div *ngIf="addressForm.controls.phone.touched && addressForm.controls.phone.errors?.required"
                                              class="text text-danger">
                                             Phone No is required.
                                         </div>
                                         <div *ngIf="addressForm.controls.phone.touched && addressForm.controls.phone.errors?.pattern"
                                              class="text text-danger">
                                             Phone No is must be number.
                                         </div>
                                     </div>
                                     <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                         <div class="field-label">Email Address</div>
                                         <input [formControl]="addressForm.controls['email']" autocomplete="off"
                                                name="email" placeholder=""
                                                type="text" value="">
                                         <div *ngIf="addressForm.controls.email.touched && addressForm.controls.email.errors?.required"
                                              class="text text-danger">
                                             Email is required
                                         </div>
                                         <div *ngIf="addressForm.controls.email.touched && addressForm.controls.email.errors?.email"
                                              class="text text-danger">
                                             Invalid Email
                                         </div>
                                     </div>
                                     <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                         <div class="field-label">Country</div>
                                         <select [formControl]="addressForm.controls['country']" name="country">
                                             <option selected value="">Select Country</option>
                                             <option value="India">India</option>
                                             <option value="USA">USA</option>
                                             <option value="Australia">Australia</option>
                                         </select>
                                         <div *ngIf="addressForm.controls.country.touched && addressForm.controls.country.errors?.required"
                                              class="text text-danger">
                                             Country is required
                                         </div>
                                     </div>
                                     <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                         <div class="field-label">Address</div>
                                         <input [formControl]="addressForm.controls['address']" autocomplete="off"
                                                name="address" placeholder=""
                                                type="text" value="">
                                         <div *ngIf="addressForm.controls.address.touched && addressForm.controls.address.errors?.required"
                                              class="text text-danger">
                                             Address is required
                                         </div>
                                         <div *ngIf="addressForm.controls.address.touched && addressForm.controls.address.errors?.maxlength"
                                              class="text text-danger">
                                             Maximum 50 character
                                         </div>
                                     </div>
                                     <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                         <div class="field-label">Town/City</div>
                                         <input [formControl]="addressForm.controls['town']" autocomplete="off"
                                                name="town" placeholder=""
                                                type="text" value="">
                                         <div *ngIf="addressForm.controls.town.touched && addressForm.controls.town.errors?.required"
                                              class="text text-danger">
                                             City is required
                                         </div>
                                     </div>
                                     <div class="form-group col-md-12 col-sm-6 col-xs-12">
                                         <div class="field-label">State</div>
                                         <input [formControl]="addressForm.controls['state']" autocomplete="off"
                                                name="state" placeholder=""
                                                type="text" value="">
                                         <div *ngIf="addressForm.controls.state.touched && addressForm.controls.state.errors?.required"
                                              class="text text-danger">
                                             State is required
                                         </div>
                                     </div>
                                     <div class="form-group col-md-12 col-sm-6 col-xs-12">
                                         <div class="field-label">Postal Code</div>
                                         <input [formControl]="addressForm.controls['postalcode']" autocomplete="off"
                                                name="postalcode" placeholder=""
                                                type="text" value="">
                                         <div *ngIf="addressForm.controls.postalcode.touched && addressForm.controls.postalcode.errors?.required"
                                              class="text text-danger">
                                             Postalcode is required
                                         </div>
                                     </div>
                                 </div>
                                 <div class="row ">
                                     <div class="col-12">
                                         <div class="stripe-section">
                                             <h5>stripe js</h5>
                                             <div class="content">
                                                 <h5>dummy test</h5>
                                                 <table>
                                                     <tr>
                                                         <td>cart number</td>
                                                         <td>4242424242424242</td>
                                                     </tr>
                                                     <tr>
                                                         <td>mm/yy</td>
                                                         <td>2/2020</td>
                                                     </tr>
                                                     <tr>
                                                         <td>cvc</td>
                                                         <td>2222</td>
                                                     </tr>
                                                 </table>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                                 <div class="row margin-cls">
                                     <div class="col-12">
                                         <div class="stripe-section">
                                             <h5>paypal</h5>
                                             <div class="content">
                                                 <h5>dummy test</h5>
                                                 <table>
                                                     <tr>
                                                         <td>cart number</td>
                                                         <td>4152521541244</td>
                                                     </tr>
                                                     <tr>
                                                         <td>mm/yy</td>
                                                         <td>11/18</td>
                                                     </tr>
                                                     <tr>
                                                         <td>cvc</td>
                                                         <td>521</td>
                                                     </tr>
                                                 </table>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                             </div>
                         </div>-->
