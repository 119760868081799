<div class="img-wrapper card-img-top">
  <div class="img-block">
    <!-- <div class="lable-wrapper">
         <span class="lable1" *ngIf="product.new">new</span>
         <span class="lable2" *ngIf="product.sale">{{ product?.discount }}% off</span>
     </div>-->
    <div class="front">
      <a class="product-img" [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]">
        <!-- <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'"
              [lazyLoad]="ImageSrc ? ImageSrc : product.images[0].src"
              alt="{{ product.images[0].alt }}" class="img-fluid lazy-loading">-->
        <div class="product-image-bg"
             [ngStyle]="{ 'background-image': 'url(' + product?.images[0].src + ')' }"></div>
      </a>
    </div>
    <div *ngIf="onHowerChangeImage" class="back">
      <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]">
        <img [src]="ImageSrc ? ImageSrc : product.images[1].src" alt="{{ product.images[1].alt }}"
             class="img-fluid lazy-loading">
      </a>
    </div>
  </div>
</div>
<div class="product-detail card-body">
  <div class="pDescription">
    <a>
      <span class="badge badge-pill">{{ product?.MARKA | titlecase }}</span>
      <h6 class="product-name">{{ product?.WEBACIKLAMA | titlecase }}</h6>
    </a>
    <!--      <h4>-->
    <!--        {{ product?.price * currency?.price | discount:product | currency:currency?.currency:'symbol' }}-->
    <!--        <del *ngIf="product?.discount"><span class="money"> {{ product?.price * currency?.price | currency:currency?.currency:'symbol' }}</span></del>-->
    <!--      </h4>-->

  </div>

</div>
<div class="card-footer">
  <div class="price_wrapper row no-gutters justify-content-center align-content-center">

    <div class="col-12">
      <div class="price d-flex justify-content-between">

        <div class="grid">
          <ng-container *ngIf="product.discountPrice; else noDiscount">
            {{
            product?.discountPrice
              | currency: product?.currency:"symbol-narrow"
            }}
            <del *ngIf="product?.discountPrice"
            ><span class="money">
                {{
              product?.displayPrice | currency: product?.currency:"symbol"
              }}</span
            >
            </del>
          </ng-container>
          <ng-template #noDiscount>
            {{
            product?.displayPrice
              | currency: product?.currency:"symbol-narrow"
            }}
          </ng-template>
        </div>
        <div class="quickviewContainer">
          <div class="quick">
            <!-- <div id="button_quickview639743">
                 <a class="quickViewConfig2020Results" data-productid="{{product.STOKNO}}"
                    title="Bu ürün hakkında daha fazla bilgi edinin">
                     <i class="fa fa-info-circle"></i>
                     <span class="hide-text text-hide">Bu ürün hakkında daha fazla bilgi edinin</span>
                 </a>
             </div>-->
            <span class="inventoryCnt">
                            {{product.stock}}
              <span class="msgInStock">STOKTA</span>
                        </span>
          </div>
        </div>
      </div>

    </div>
    <div class="col-6 text-center">
      <div (click)="selectProduct(product)" class="btn btn-block btn-outline-primary btn-select ">Seç</div>
    </div>
  </div>

</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view #quickView [currency]="currency" [product]="product"></app-quick-view>
