import { AfterContentChecked, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-display-tl-conversion',
  templateUrl: './display-tl-conversion.component.html',
  styleUrls: ['./display-tl-conversion.component.scss'],
})
export class DisplayTlConversionComponent implements OnInit, AfterContentChecked {
  @Input() payPointResult;
  convertedToTl;
  constructor() {}

  ngOnInit(): void {}

  ngAfterContentChecked(): void {
    // Called after every check of the component's or directive's content.
    // Add 'implements AfterContentChecked' to the class.
    if (this.payPointResult) {
      this.convertedToTl = this.payPointResult.ConversionResult;
    }
  }
}
