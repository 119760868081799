import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavService } from '../../services/nav.service';
import { Store } from '@ngrx/store';
import { AppState, selectAuthState } from '../../../_store/app.states';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AccountService } from '../../services';

@Component({
  selector: 'app-mobile-rightside-nav',
  templateUrl: './mobile-rightside-nav.component.html',
  styleUrls: ['./mobile-rightside-nav.component.scss'],
})
export class MobileRightsideNavComponent implements OnInit {
  @Input() greetings: string;
  @Input() user: any;

  cartItems: any[] = [];
  private getState: Observable<AppState>;

  constructor(public navService: NavService, private router: Router, private accountService: AccountService) {
    this.greetings = this.accountService.greetings;
    this.user = this.accountService.user;
  }

  ngOnInit(): void {}

  navigateUserAccount() {
    this.toggleRightMobileMenu();
    this.router.navigate(['/account']).catch((e) => {
      console.log(e);
    });
  }

  navigate(url: string) {
    this.router.navigateByUrl(url).catch();
    this.toggleRightMobileMenu();
  }

  toggleRightMobileMenu() {
    this.navService.rightMobileMenuToggle = !this.navService.rightMobileMenuToggle;
  }

  logOut() {
    this.accountService.logOut();
    this.toggleRightMobileMenu();
    this.user = null;
  }

  onHover(menuItem) {
    if (window.innerWidth > 1200 && menuItem) {
      document.getElementById('unsetTwo').classList.add('sidebar-unset');
    } else {
      document.getElementById('unsetTwo').classList.remove('sidebar-unset');
    }
  }
}
