import { Injectable } from '@angular/core';
import {  throwError } from 'rxjs';
import { Router } from '@angular/router';
import {
  HttpErrorResponse,

} from '@angular/common/http';



@Injectable({
    providedIn: 'root',
})
export class ErrorHandlerService {

    constructor(
        private router: Router,
    ) {

    }

     handleError(error: HttpErrorResponse | any) {
         console.log(error);

         // Return an observable with a user-facing error message.
         //  this.toastNotification.error(err||'Error; please try again later.')
         return throwError(error || 'Error; please try again later.');
    }
}
