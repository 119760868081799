import {
  AfterContentChecked,
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { Product } from "../../../shared/classes/product";
import { ProductService } from "../../../shared/services/product.service";
import { Observable } from "rxjs";
import { CartService } from "../../../shared/services/cart.service";
import { HelperService } from "../../../shared/services/helper.service";
import { User, UserType } from "../../../shared/classes/user";

@Component({
  selector: "app-checkout-cart",
  templateUrl: "./checkout-cart.component.html",
  styleUrls: ["./checkout-cart.component.scss"],
})
export class CheckoutCartComponent
  implements OnInit, AfterContentInit, AfterContentChecked
{
  @Input() products: Product[] = [];
  @Input() onlinePayment = false;
  @Input() payPontResult;
  @Output() totalTRY = new EventEmitter();
  @Output() totalUSD = new EventEmitter();
  @Input() isTRY = false;
  @Input() isUSD = false;
  @Input() user: User;
  showLimit = 4;
  tlTotal = 0;
  usdTotal = 0;
  convertedToTl;
  isSent = false;
  loaded: boolean;
  userType = UserType;
  private stopCheck: Boolean;

  constructor(
    public productService: ProductService,
    public cartService: CartService,
    public helpers: HelperService
  ) {}

  ngOnInit(): void {
    this.getUSDTotal.subscribe((total) => {
      this.totalUSD.emit(total);
      this.usdTotal = total;
    });
    this.getTRYTotal.subscribe((total) => {
      this.totalTRY.emit(total);
      this.tlTotal = total;
    });
    this.helpers.deliveryFeeEvent$.subscribe((value) => {
      if (typeof value !== null) {
      }
    });
  }

  ngAfterContentInit(): void {
    if (this.products.length > 0) {
      this.loaded = true;
    }
  }

  ngAfterContentChecked() {
    if (this.products.length > 0 && !this.stopCheck) {
      // this.helpers.getUSDConversionRate()
      this.getConversionRate();
      this.stopCheck = true;
    }
  }

  showAllProducts(products) {
    this.showLimit = products.length + this.showLimit;
  }

  public get getUSDTotal(): Observable<number> {
    const totalUSD = this.cartService.cartTotalAmount("USD");
    // totalUSD.subscribe((total) => {
    //   this.totalUSD.emit(total);
    //   this.usdTotal = total;
    // });
    return totalUSD;
  }

  public get getTRYTotal(): Observable<number> {
    const totalTRY = this.cartService.cartTotalAmount("TRY");
    // totalTRY.subscribe((total) => {
    //   this.totalTRY.emit(total);
    //   this.tlTotal = total;
    // });
    return totalTRY;
  }

  public getConversionRate() {}

  public usdToTLConversion() {
    if (this.convertedToTl) {
      return;
    }
    const ss = this.helpers.getUSDConversionRate(this.usdTotal);

    const er = ss.subscribe(
      (response) => {
        this.isSent = true;
        this.convertedToTl = response;
        er.unsubscribe();
      },
      (err) => console.log(err)
    );
  }
}
