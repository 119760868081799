import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Menu, NavService } from "../../services/nav.service";
import { Router } from "@angular/router";
import * as _ from "lodash";
import { NgbNav, NgbNavConfig } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-left-menu",
  templateUrl: "./left-menu.component.html",
  styleUrls: ["./left-menu.component.scss"],
  providers: [NgbNavConfig],
})
export class LeftMenuComponent implements OnInit {
  public menuItems: Menu[];
  @Input() title: string;
  @ViewChild("nav") nav: NgbNav;
  menuActive = 1;
  regEx = /\s/g;
  menuItem = {
    children: [],
    title: "",
    icon: "",
    slugName: "",
  };
  active;
  public themeLogo = "assets/images/logos/alogo.png"; // Default Logo
  constructor(
    private router: Router,
    public navServices: NavService,
    config: NgbNavConfig
  ) {}

  ngOnInit(): void {
    this.navServices.getNewMenu.subscribe((menu) => {
      this.menuItems = menu; // menu.sort((a, b) => a.title.localeCompare(b.title));
    });
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
  }

  toggleActive(event, item) {
    this.menuActive = item.id;
    this.active = item.id;
    item.children = _.unionBy(item.children, "title");
    item.childrn = item.children.sort((a, b) =>
      new Intl.Collator().compare(a.title, b.title)
    );
    this.menuItem = item;
  }

  leftMenuToggle(): void {
    this.navServices.leftMenuToggle = true;
  }

  closeMenu() {
    this.navServices.leftMenuToggle = false;
  }
}
