import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { AccountService } from '../../shared/services';
import { AppState, selectAuthState } from '../../_store/app.states';
import { Store } from '@ngrx/store';
import { LogIn, LogInErrorReset } from '../../_store/actions/auth.action';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  showConsumerForm: boolean;
  retailerForm: FormGroup;
  consumerForm: FormGroup;
  loading = false;
  submitted = false;
  showRetailerForm: boolean;
  // private title: string;
  pageTitle: string;
  returnUrl: string;
  getState: Observable<any>;
  errorMessage: string | null;
  private url: string;
  private routerSubscribe: Subscription;
  private userType: string;
  showAccountUpdateForm: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastrService,
    private accountService: AccountService,
    private store: Store<AppState>
  ) {
    this.routerSubscribe = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
        if (event.url === '/auth/bayi-giris') {
          this.initRetailerForm();
          this.title = 'Bayi Giris';
          this.userType = 'retailer';
        } else {
          this.showRetailerForm = false;
          this.showConsumerForm = true;
          this.initConsumerForm();
          this.title = 'Üye Girişi';
          this.userType = 'customer';
        }
      }
    });
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';

    this.getState = this.store.select(selectAuthState);
  }

  set title(title: string) {
    this.pageTitle = title;
  }

  close(s) {
    this.errorMessage = null;
  }

  ngOnDestroy() {
    this.routerSubscribe.unsubscribe();
  }

  ngOnInit(): void {
    this.store.dispatch(LogInErrorReset());
    this.getState.subscribe((state) => {
      this.errorMessage = state.errorMessage;
      this.loading = state.isAuthenticated;
      if (state?.errorCode && state.errorCode === 'accountNotFoundInWebSystem') {
        // this.showAccountUpdateForm = true;
        // this.store.dispatch(LogInErrorReset());
        this.router.navigate(['auth/bayi-hesabi']);
      }
    });
  }

  loginRetailer() {
    if (this.retailerForm.invalid) {
      this.toastService.error('Enter your customer code and password');
      return;
    }
    this.performLogin(this.retailerForm.value);
  }

  loginConsumer() {
    if (this.consumerForm.invalid) {
      this.toastService.error('Please enter email and password');
      return;
    }
    this.performLogin(this.consumerForm.value);
  }

  private performLogin(values) {
    this.loading = true;
    values.userType = this.userType;
    values.redirect = this.returnUrl;
    this.store.dispatch(LogIn(values));
  }

  private initRetailerForm() {
    this.showRetailerForm = true;
    this.retailerForm = this.formBuilder.group({
      retailerCode: ['', Validators.required],
      // retailerName: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  private initConsumerForm() {
    this.consumerForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.email, Validators.required])],
      password: ['', Validators.compose([Validators.required])],
    });
  }
}
