import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { ApiUrl, UserType } from '../interface/interface';
import {
  HttpErrorResponse,

} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';


const API_URL = ApiUrl;
const userType = UserType.admin;
@Injectable({
    providedIn: 'root',
})
export class ErrorHandlerService {

    constructor(
        private router: Router,
        private toastNotification: ToastrService
    ) {

    }

     handleError(error: HttpErrorResponse | any) {
        let err = '';
        // use a remote logging infrastructure
        if (error.status === 0) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${error.status}, ` + `body was: ${error.error}`
            );
            console.log(error);
            err = error?.error?.message || error;
        }
        // Return an observable with a user-facing error message.
        //  this.toastNotification.error(err||'Error; please try again later.')
        return throwError(err || 'Error; please try again later.');
    }
}
