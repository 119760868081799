import { AbstractControl } from '@angular/forms';
import { environment } from '../../../../environments/environment.prod';

export interface LoginFormDataInterface {
  email: string;
  password: string;
  user: string;
}

export interface Notifications {
  senderId: number;
  extras: string;
  message: string;
  createdAt: Date;
  senderType: string;
  receipt: string;
  senderName: string;
  length?: number;
}

export interface Product {
  section?: string;
  produt_name: string;
}

export const UserType = {
  admin: 'admin',
  shop: 'shopOwner',
};

export const CURRENCY = '$';
export const ApiUrl = {
  dev: environment.apiUrlTest, //'http://localhost/server/asbil-backend/router.php/', // https://yaslihaklariveruhsagligi.org/api/router.php/
  local: environment.apiUrlTest, //'http://localhost/server/asbil-backend/router.php/', // http://localhost/server/asbil-backend/router.php/
  prod: environment.apiUrl,
};

export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const passwordMismatch = (control: AbstractControl): { [key: string]: boolean } => {
  const password = control.get('password');
  const confirm_password = control.get('confirm_password');
  if (!password || !confirm_password) {
    return null;
  }
  const d = password.value === confirm_password.value ? null : { mismatch: true };
  console.log(d);
  return d;
};
