<div class="navbar">
  <a href="javascript:void(0)" (click)="rightMenuToggle()">
    <div class="bar-style">MARKALAR</div>
  </a>
  <div id="mySidenav" class="sidenav brands-menu" [class.openSide]="navServices?.rightMenuToggle">
    <a
      href="javascript:void(0)"
      class="sidebar-overlay"
      (click)="rightMenuToggle('getBrands')"
      (mouseover)="onHover(false)"
    ></a>
    <nav id="unSetter">
      <div class="header-sect">
        <div (click)="rightMenuToggle()">
          <div class="sidebar-back text-left"><i class="fa fa-long-arrow-left pr-2" aria-hidden="true"></i> Geri</div>
        </div>
        <div class="brand-search">
          <input
            type="search"
            class="form-control"
            id="search-text"
            aria-describedby="search-text"
            [(ngModel)]="searchText"
            placeholder="Marka ara"
            autofocus
          />
        </div>
      </div>
      <ul id="sub-menu" class="sidebar-menu brands-menu-items">
        <li *ngFor="let brand of brands | searchFilter: searchText : ''">
          <a
            href="javascript:void(0)"
            highlight
            [searchedWord]="searchText"
            [content]="brand"
            [classToApply]="'font-weight-bold'"
            [setTitle]="true"
            (click)="showProductsByBrand(brand)"
          >
            {{ brand }}
          </a>
        </li>
      </ul>
    </nav>
  </div>
</div>
